@charset "utf-8";
/**
 * mixins
 */

/*
メディアクエリ
 */

@mixin sp {
    @media print, screen and (max-width: $breakPointSp) {
        @content;
    }
}
@mixin tab {
    @media print, screen and (min-width: $breakPointTab) {
        @content;
    }
}
@mixin pc {
    @media print, screen and (min-width: $breakPointPc) {
        @content;
    }
}
@mixin pc2 {
    @media print, screen and (min-width: $breakPointPc2) {
        @content;
    }
}


/*
is-hover
 */
@mixin isHover {
    &.is-hover {
        opacity: 0.85;
    }
}


//インデント
@mixin indent {
    text-indent: -1em;
    padding-left: 1em;
}

//placeholderColor
@mixin placeholderColor($color) {
    &:placeholder-shown {
        color: $color;
    }
    &::-webkit-input-placeholder {
        color:$color;
    }
    &:-moz-placeholder {
        color:$color;
        opacity: 1;
    }
    &::-moz-placeholder {
        color:$color;
        opacity: 1;
    }
    &:-ms-input-placeholder {
        color:$color;
    }
}