@charset "utf-8";

//ブレイクポイント
$breakPointSp: 767px;
$breakPointTab: 768px;
$breakPointPc: 1280px;
$breakPointPc2: 1920px;

//サイト共通のフォントカラー
$blue: #0080c7;
$blue_02: #c9e3f1;
$green: #00c779;
$red: #d2030a;
$orange: #f18d1e;
$gray_01: #ececec;
$gray_02: #888888;

//リンクの設定
$color_lnk: #000;//リンクのカラー
$link_deco:none;//リンクのデコレーション設定

// font
$font-base: "游ゴシック体", YuGothic, "Yu Gothic Medium", "Yu Gothic UI", sans-serif;
$font-ubunts: 'Ubuntu';
$font-roboto: 'Roboto', sans-serif;



