@charset "utf-8";
@import "_initialize";
@import "_properties";
@import "_mixin";

/*
*/
/*
* Common
*/
html, body {
	width: 100%;
	height: 100%;
}
html {
	color: #000;
	font-family: $font-base;
	font-size: 62.5%;
	font-weight: 400;
	line-height: 1.6;
	letter-spacing: 0.1em;
}
body {
	font-size: 1.4em;
	position: relative;
	overflow-y: auto;
	text-align: center;
}
strong, b
{
	font-weight: bold;
}
ul {
	list-style-type: none;
}
img {
	max-width: 100%;
	vertical-align: middle;
}
hr {
	display: none;
}
a {
	@include isHover;
}
.pci_only {
	@include sp {
		display: none;
	}
	@include tab {
		display: inline-block;
	}
}
.spi_only {
	@include sp {
		display: inline-block;
	}
	@include tab {
		display: none;
	}
}
.pc_only {
	@include sp {
		display: none;
	}
	@include tab {
		display: block;
	}
}
.sp_only {
	@include sp {
		display: block;
	}
	@include tab {
		display: none;
	}
}