@charset "UTF-8";
/*!
 * リセット用
 */
/**
 * mixins
 */
/*
メディアクエリ
 */
/*
is-hover
 */
/*
*/
/*
* Common
*/
html, body {
  width: 100%;
  height: 100%;
}

html {
  color: #000;
  font-family: "游ゴシック体", YuGothic, "Yu Gothic Medium", "Yu Gothic UI", sans-serif;
  font-size: 62.5%;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.1em;
}

body {
  font-size: 1.4em;
  position: relative;
  overflow-y: auto;
  text-align: center;
}

strong, b {
  font-weight: bold;
}

ul {
  list-style-type: none;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

hr {
  display: none;
}

a.is-hover {
  opacity: 0.85;
}

@media print, screen and (max-width: 767px) {
  .pci_only {
    display: none;
  }
}

@media print, screen and (min-width: 768px) {
  .pci_only {
    display: inline-block;
  }
}

@media print, screen and (max-width: 767px) {
  .spi_only {
    display: inline-block;
  }
}

@media print, screen and (min-width: 768px) {
  .spi_only {
    display: none;
  }
}

@media print, screen and (max-width: 767px) {
  .pc_only {
    display: none;
  }
}

@media print, screen and (min-width: 768px) {
  .pc_only {
    display: block;
  }
}

@media print, screen and (max-width: 767px) {
  .sp_only {
    display: block;
  }
}

@media print, screen and (min-width: 768px) {
  .sp_only {
    display: none;
  }
}
